.cpd-theme-light-hc.cpd-theme-light-hc {
  --cpd-color-alpha-pink-1400: hsl(341, 100%, 13%, 1);
  --cpd-color-alpha-pink-1300: hsl(337, 100%, 15%, 1);
  --cpd-color-alpha-pink-1200: hsl(332, 100%, 20%, 1);
  --cpd-color-alpha-pink-1100: hsl(329, 100%, 22%, 1);
  --cpd-color-alpha-pink-1000: hsla(330, 97%, 26%, 0.98);
  --cpd-color-alpha-pink-900: hsla(331, 100%, 28%, 0.97);
  --cpd-color-alpha-pink-800: hsla(333, 98%, 44%, 0.96);
  --cpd-color-alpha-pink-700: hsla(341, 98%, 49%, 0.7);
  --cpd-color-alpha-pink-600: hsla(345, 100%, 50%, 0.47);
  --cpd-color-alpha-pink-500: hsla(346, 100%, 51%, 0.33);
  --cpd-color-alpha-pink-400: hsla(347, 100%, 52%, 0.19);
  --cpd-color-alpha-pink-300: hsla(347, 100%, 50%, 0.13);
  --cpd-color-alpha-pink-200: hsla(347, 100%, 54%, 0.08);
  --cpd-color-alpha-pink-100: hsla(348, 100%, 51%, 0.04);
  --cpd-color-alpha-fuchsia-1400: hsl(281, 100%, 15%, 1);
  --cpd-color-alpha-fuchsia-1300: hsl(283, 100%, 16%, 1);
  --cpd-color-alpha-fuchsia-1200: hsl(285, 96%, 22%, 1);
  --cpd-color-alpha-fuchsia-1100: hsla(285, 97%, 22%, 0.95);
  --cpd-color-alpha-fuchsia-1000: hsla(286, 100%, 24%, 0.91);
  --cpd-color-alpha-fuchsia-900: hsla(287, 96%, 27%, 0.9);
  --cpd-color-alpha-fuchsia-800: hsla(294, 98%, 36%, 0.79);
  --cpd-color-alpha-fuchsia-700: hsla(295, 97%, 37%, 0.6);
  --cpd-color-alpha-fuchsia-600: hsla(295, 96%, 37%, 0.43);
  --cpd-color-alpha-fuchsia-500: hsla(297, 93%, 38%, 0.31);
  --cpd-color-alpha-fuchsia-400: hsla(296, 95%, 38%, 0.18);
  --cpd-color-alpha-fuchsia-300: hsla(298, 91%, 40%, 0.13);
  --cpd-color-alpha-fuchsia-200: hsla(295, 89%, 41%, 0.07);
  --cpd-color-alpha-fuchsia-100: hsla(293, 95%, 41%, 0.04);
  --cpd-color-alpha-purple-1400: hsl(259, 100%, 19%, 1);
  --cpd-color-alpha-purple-1300: hsl(259, 100%, 22%, 1);
  --cpd-color-alpha-purple-1200: hsl(262, 100%, 29%, 1);
  --cpd-color-alpha-purple-1100: hsl(264, 100%, 33%, 1);
  --cpd-color-alpha-purple-1000: hsla(263, 100%, 36%, 0.95);
  --cpd-color-alpha-purple-900: hsla(261, 97%, 38%, 0.9);
  --cpd-color-alpha-purple-800: hsla(258, 98%, 49%, 0.7);
  --cpd-color-alpha-purple-700: hsla(254, 100%, 50%, 0.53);
  --cpd-color-alpha-purple-600: hsla(251, 100%, 51%, 0.38);
  --cpd-color-alpha-purple-500: hsla(249, 100%, 51%, 0.27);
  --cpd-color-alpha-purple-400: hsla(248, 100%, 52%, 0.16);
  --cpd-color-alpha-purple-300: hsla(248, 100%, 53%, 0.12);
  --cpd-color-alpha-purple-200: hsla(248, 100%, 55%, 0.07);
  --cpd-color-alpha-purple-100: hsla(248, 100%, 61%, 0.04);
  --cpd-color-alpha-blue-1400: hsl(233, 100%, 19%, 1);
  --cpd-color-alpha-blue-1300: hsl(228, 100%, 21%, 1);
  --cpd-color-alpha-blue-1200: hsla(222, 100%, 24%, 0.99);
  --cpd-color-alpha-blue-1100: hsla(220, 99%, 27%, 0.99);
  --cpd-color-alpha-blue-1000: hsla(218, 97%, 30%, 0.99);
  --cpd-color-alpha-blue-900: hsla(217, 98%, 33%, 0.99);
  --cpd-color-alpha-blue-800: hsla(212, 99%, 46%, 0.99);
  --cpd-color-alpha-blue-700: hsla(215, 100%, 46%, 0.71);
  --cpd-color-alpha-blue-600: hsla(215, 98%, 47%, 0.51);
  --cpd-color-alpha-blue-500: hsla(216, 95%, 48%, 0.37);
  --cpd-color-alpha-blue-400: hsla(216, 96%, 49%, 0.22);
  --cpd-color-alpha-blue-300: hsla(216, 95%, 51%, 0.16);
  --cpd-color-alpha-blue-200: hsla(215, 100%, 52%, 0.09);
  --cpd-color-alpha-blue-100: hsla(218, 100%, 57%, 0.05);
  --cpd-color-alpha-cyan-1400: hsl(222, 100%, 15%, 1);
  --cpd-color-alpha-cyan-1300: hsl(218, 100%, 16%, 1);
  --cpd-color-alpha-cyan-1200: hsl(212, 100%, 20%, 1);
  --cpd-color-alpha-cyan-1100: hsl(210, 100%, 22%, 1);
  --cpd-color-alpha-cyan-1000: hsl(207, 100%, 24%, 1);
  --cpd-color-alpha-cyan-900: hsl(205, 100%, 26%, 1);
  --cpd-color-alpha-cyan-800: hsl(199, 100%, 35%, 1);
  --cpd-color-alpha-cyan-700: hsl(193, 100%, 38%, 1);
  --cpd-color-alpha-cyan-600: hsla(186, 99%, 40%, 0.92);
  --cpd-color-alpha-cyan-500: hsla(187, 99%, 38%, 0.54);
  --cpd-color-alpha-cyan-400: hsla(187, 97%, 38%, 0.31);
  --cpd-color-alpha-cyan-300: hsla(186, 100%, 37%, 0.22);
  --cpd-color-alpha-cyan-200: hsla(188, 100%, 38%, 0.11);
  --cpd-color-alpha-cyan-100: hsla(186, 79%, 41%, 0.06);
  --cpd-color-alpha-green-1400: hsl(147, 100%, 7%, 1);
  --cpd-color-alpha-green-1300: hsl(152, 100%, 8%, 1);
  --cpd-color-alpha-green-1200: hsl(158, 100%, 11%, 1);
  --cpd-color-alpha-green-1100: hsl(160, 100%, 13%, 1);
  --cpd-color-alpha-green-1000: hsl(162, 100%, 15%, 1);
  --cpd-color-alpha-green-900: hsl(163, 100%, 17%, 1);
  --cpd-color-alpha-green-800: hsl(168, 100%, 26%, 1);
  --cpd-color-alpha-green-700: hsl(166, 100%, 32%, 1);
  --cpd-color-alpha-green-600: hsla(163, 99%, 38%, 0.96);
  --cpd-color-alpha-green-500: hsla(156, 99%, 36%, 0.56);
  --cpd-color-alpha-green-400: hsla(153, 95%, 37%, 0.32);
  --cpd-color-alpha-green-300: hsla(151, 93%, 37%, 0.23);
  --cpd-color-alpha-green-200: hsla(150, 100%, 36%, 0.11);
  --cpd-color-alpha-green-100: hsla(150, 79%, 41%, 0.06);
  --cpd-color-alpha-lime-1400: hsl(120, 100%, 7%, 1);
  --cpd-color-alpha-lime-1300: hsl(120, 100%, 8%, 1);
  --cpd-color-alpha-lime-1200: hsl(120, 100%, 11%, 1);
  --cpd-color-alpha-lime-1100: hsl(120, 100%, 13%, 1);
  --cpd-color-alpha-lime-1000: hsl(120, 100%, 16%, 1);
  --cpd-color-alpha-lime-900: hsl(120, 100%, 17%, 1);
  --cpd-color-alpha-lime-800: hsla(112, 97%, 25%, 0.95);
  --cpd-color-alpha-lime-700: hsla(106, 99%, 30%, 0.9);
  --cpd-color-alpha-lime-600: hsla(102, 100%, 37%, 0.86);
  --cpd-color-alpha-lime-500: hsla(102, 97%, 41%, 0.71);
  --cpd-color-alpha-lime-400: hsla(105, 95%, 41%, 0.4);
  --cpd-color-alpha-lime-300: hsla(105, 92%, 42%, 0.28);
  --cpd-color-alpha-lime-200: hsla(107, 98%, 41%, 0.15);
  --cpd-color-alpha-lime-100: hsla(107, 89%, 44%, 0.07);
  --cpd-color-alpha-yellow-1400: hsl(5, 100%, 12%, 1);
  --cpd-color-alpha-yellow-1300: hsl(10, 100%, 14%, 1);
  --cpd-color-alpha-yellow-1200: hsl(20, 100%, 17%, 1);
  --cpd-color-alpha-yellow-1100: hsl(24, 100%, 19%, 1);
  --cpd-color-alpha-yellow-1000: hsl(27, 100%, 22%, 1);
  --cpd-color-alpha-yellow-900: hsl(28, 100%, 23%, 1);
  --cpd-color-alpha-yellow-800: hsl(36, 100%, 33%, 1);
  --cpd-color-alpha-yellow-700: hsl(40, 100%, 38%, 1);
  --cpd-color-alpha-yellow-600: hsl(44, 100%, 44%, 1);
  --cpd-color-alpha-yellow-500: hsl(47, 100%, 47%, 1);
  --cpd-color-alpha-yellow-400: hsla(48, 100%, 50%, 0.72);
  --cpd-color-alpha-yellow-300: hsla(47, 100%, 51%, 0.49);
  --cpd-color-alpha-yellow-200: hsla(47, 100%, 51%, 0.25);
  --cpd-color-alpha-yellow-100: hsla(46, 100%, 53%, 0.13);
  --cpd-color-alpha-orange-1400: hsl(0, 100%, 13%, 1);
  --cpd-color-alpha-orange-1300: hsl(0, 100%, 15%, 1);
  --cpd-color-alpha-orange-1200: hsl(0, 100%, 20%, 1);
  --cpd-color-alpha-orange-1100: hsl(0, 100%, 23%, 1);
  --cpd-color-alpha-orange-1000: hsl(4, 100%, 27%, 1);
  --cpd-color-alpha-orange-900: hsl(9, 100%, 29%, 1);
  --cpd-color-alpha-orange-800: hsl(24, 100%, 38%, 1);
  --cpd-color-alpha-orange-700: hsl(29, 100%, 44%, 1);
  --cpd-color-alpha-orange-600: hsla(27, 100%, 48%, 0.75);
  --cpd-color-alpha-orange-500: hsla(26, 98%, 50%, 0.52);
  --cpd-color-alpha-orange-400: hsla(25, 100%, 52%, 0.3);
  --cpd-color-alpha-orange-300: hsla(25, 100%, 51%, 0.22);
  --cpd-color-alpha-orange-200: hsla(24, 100%, 52%, 0.11);
  --cpd-color-alpha-orange-100: hsla(26, 100%, 55%, 0.07);
  --cpd-color-alpha-red-1400: hsl(0, 100%, 13%, 1);
  --cpd-color-alpha-red-1300: hsl(0, 100%, 15%, 1);
  --cpd-color-alpha-red-1200: hsl(0, 100%, 20%, 1);
  --cpd-color-alpha-red-1100: hsl(0, 100%, 23%, 1);
  --cpd-color-alpha-red-1000: hsl(355, 100%, 27%, 1);
  --cpd-color-alpha-red-900: hsl(350, 100%, 30%, 1);
  --cpd-color-alpha-red-800: hsla(356, 98%, 44%, 0.89);
  --cpd-color-alpha-red-700: hsla(2, 100%, 50%, 0.72);
  --cpd-color-alpha-red-600: hsla(5, 100%, 51%, 0.5);
  --cpd-color-alpha-red-500: hsla(7, 100%, 51%, 0.36);
  --cpd-color-alpha-red-400: hsla(8, 100%, 51%, 0.21);
  --cpd-color-alpha-red-300: hsla(8, 100%, 52%, 0.15);
  --cpd-color-alpha-red-200: hsla(9, 100%, 54%, 0.08);
  --cpd-color-alpha-red-100: hsla(7, 100%, 56%, 0.04);
  --cpd-color-alpha-gray-1400: hsla(223, 78%, 2%, 0.9);
  --cpd-color-alpha-gray-1300: hsla(218, 100%, 2%, 0.88);
  --cpd-color-alpha-gray-1200: hsla(210, 67%, 2%, 0.83);
  --cpd-color-alpha-gray-1100: hsla(222, 63%, 3%, 0.8);
  --cpd-color-alpha-gray-1000: hsla(220, 75%, 3%, 0.76);
  --cpd-color-alpha-gray-900: hsla(223, 70%, 4%, 0.73);
  --cpd-color-alpha-gray-800: hsla(217, 89%, 7%, 0.58);
  --cpd-color-alpha-gray-700: hsla(216, 81%, 10%, 0.48);
  --cpd-color-alpha-gray-600: hsla(215, 96%, 10%, 0.35);
  --cpd-color-alpha-gray-500: hsla(212, 97%, 12%, 0.26);
  --cpd-color-alpha-gray-400: hsla(215, 88%, 17%, 0.16);
  --cpd-color-alpha-gray-300: hsla(213, 90%, 20%, 0.12);
  --cpd-color-alpha-gray-200: hsla(216, 89%, 18%, 0.06);
  --cpd-color-alpha-gray-100: hsla(200, 41%, 36%, 0.04);
  --cpd-color-pink-1400: #410015;
  --cpd-color-pink-1300: #4a001c;
  --cpd-color-pink-1200: #64002f;
  --cpd-color-pink-1100: #72003a;
  --cpd-color-pink-1000: #840745;
  --cpd-color-pink-900: #92084b;
  --cpd-color-pink-800: #e00c6a;
  --cpd-color-pink-700: #f94e84;
  --cpd-color-pink-600: #ff88a6;
  --cpd-color-pink-500: #ffadc0;
  --cpd-color-pink-400: #ffd0da;
  --cpd-color-pink-300: #ffdee5;
  --cpd-color-pink-200: #ffecf0;
  --cpd-color-pink-100: #fff5f7;
  --cpd-color-fuchsia-1400: #32004a;
  --cpd-color-fuchsia-1300: #3b0053;
  --cpd-color-fuchsia-1200: #52026c;
  --cpd-color-fuchsia-1100: #5c0f76;
  --cpd-color-fuchsia-1000: #6c1785;
  --cpd-color-fuchsia-900: #781c90;
  --cpd-color-fuchsia-800: #b937c6;
  --cpd-color-fuchsia-700: #cb68d4;
  --cpd-color-fuchsia-600: #db93e1;
  --cpd-color-fuchsia-500: #e7b2ea;
  --cpd-color-fuchsia-400: #f1d2f3;
  --cpd-color-fuchsia-300: #f6dff7;
  --cpd-color-fuchsia-200: #faeefb;
  --cpd-color-fuchsia-100: #fcf5fd;
  --cpd-color-purple-1400: #1f0062;
  --cpd-color-purple-1300: #240070;
  --cpd-color-purple-1200: #360094;
  --cpd-color-purple-1100: #4200a6;
  --cpd-color-purple-1000: #4f0dba;
  --cpd-color-purple-900: #571cc4;
  --cpd-color-purple-800: #824ef9;
  --cpd-color-purple-700: #9778fe;
  --cpd-color-purple-600: #b1a0ff;
  --cpd-color-purple-500: #c5bbff;
  --cpd-color-purple-400: #ddd8ff;
  --cpd-color-purple-300: #e6e2ff;
  --cpd-color-purple-200: #f1efff;
  --cpd-color-purple-100: #f8f7ff;
  --cpd-color-blue-1400: #072033;
  --cpd-color-blue-1300: #0B3554;
  --cpd-color-blue-1200: #104B77;
  --cpd-color-blue-1100: #145791;
  --cpd-color-blue-1000: #196DB7;
  --cpd-color-blue-900: #1E88E5;
  --cpd-color-blue-800: #489AE2;
  --cpd-color-blue-700: #7eaff6;
  --cpd-color-blue-600: #a3c6fa;
  --cpd-color-blue-500: #bad5fc;
  --cpd-color-blue-400: #d8e7fe;
  --cpd-color-blue-300: #e9f2ff;
  --cpd-color-blue-200: #f4f8ff;
  --cpd-color-blue-100: #f9fcff;
  --cpd-color-cyan-1400: #00174d;
  --cpd-color-cyan-1300: #001e53;
  --cpd-color-cyan-1200: #002e64;
  --cpd-color-cyan-1100: #00376e;
  --cpd-color-cyan-1000: #00447b;
  --cpd-color-cyan-900: #004c84;
  --cpd-color-cyan-800: #007ab3;
  --cpd-color-cyan-700: #009ac3;
  --cpd-color-cyan-600: #15becf;
  --cpd-color-cyan-500: #76d1dd;
  --cpd-color-cyan-400: #b1e4eb;
  --cpd-color-cyan-300: #c7ecf0;
  --cpd-color-cyan-200: #e3f5f8;
  --cpd-color-cyan-100: #f1fafb;
  --cpd-color-green-1400: #00210f;
  --cpd-color-green-1300: #002715;
  --cpd-color-green-1200: #003723;
  --cpd-color-green-1100: #00402b;
  --cpd-color-green-1000: #004d36;
  --cpd-color-green-900: #00553d;
  --cpd-color-green-800: #008268;
  --cpd-color-green-700: #00a27c;
  --cpd-color-green-600: #0bc491;
  --cpd-color-green-500: #71d7ae;
  --cpd-color-green-400: #afe8ce;
  --cpd-color-green-300: #c6eedb;
  --cpd-color-green-200: #e3f7ed;
  --cpd-color-green-100: #f1fbf6;
  --cpd-color-lime-1400: #002200;
  --cpd-color-lime-1300: #002900;
  --cpd-color-lime-1200: #003900;
  --cpd-color-lime-1100: #004200;
  --cpd-color-lime-1000: #004f00;
  --cpd-color-lime-900: #005700;
  --cpd-color-lime-800: #1f850f;
  --cpd-color-lime-700: #3aa31a;
  --cpd-color-lime-600: #54c424;
  --cpd-color-lime-500: #76db4c;
  --cpd-color-lime-400: #afeb9b;
  --cpd-color-lime-300: #c8f1ba;
  --cpd-color-lime-200: #e0f8d9;
  --cpd-color-lime-100: #f1fcee;
  --cpd-color-yellow-1400: #3f0500;
  --cpd-color-yellow-1300: #450c00;
  --cpd-color-yellow-1200: #571d00;
  --cpd-color-yellow-1100: #612600;
  --cpd-color-yellow-1000: #6e3100;
  --cpd-color-yellow-900: #773800;
  --cpd-color-yellow-800: #a76300;
  --cpd-color-yellow-700: #c38100;
  --cpd-color-yellow-600: #dea200;
  --cpd-color-yellow-500: #f1bd00;
  --cpd-color-yellow-400: #ffda49;
  --cpd-color-yellow-300: #ffe484;
  --cpd-color-yellow-200: #fff2c1;
  --cpd-color-yellow-100: #fff8e0;
  --cpd-color-orange-1400: #420000;
  --cpd-color-orange-1300: #4c0000;
  --cpd-color-orange-1200: #670000;
  --cpd-color-orange-1100: #770000;
  --cpd-color-orange-1000: #890800;
  --cpd-color-orange-900: #931700;
  --cpd-color-orange-800: #c44d00;
  --cpd-color-orange-700: #e26e00;
  --cpd-color-orange-600: #f89440;
  --cpd-color-orange-500: #fdb37c;
  --cpd-color-orange-400: #ffd4b5;
  --cpd-color-orange-300: #ffdfc8;
  --cpd-color-orange-200: #ffefe4;
  --cpd-color-orange-100: #fff6ef;
  --cpd-color-red-1400: #420000;
  --cpd-color-red-1300: #4c0000;
  --cpd-color-red-1200: #670000;
  --cpd-color-red-1100: #770000;
  --cpd-color-red-1000: #8b000c;
  --cpd-color-red-900: #99001a;
  --cpd-color-red-800: #e11e2a;
  --cpd-color-red-700: #ff4e49;
  --cpd-color-red-600: #ff8c81;
  --cpd-color-red-500: #ffafa5;
  --cpd-color-red-400: #ffd1ca;
  --cpd-color-red-300: #ffdfda;
  --cpd-color-red-200: #ffefec;
  --cpd-color-red-100: #fff7f6;
  --cpd-color-gray-1400: #1a1c21;
  --cpd-color-gray-1300: #1f2126;
  --cpd-color-gray-1200: #2d3034;
  --cpd-color-gray-1100: #35383d;
  --cpd-color-gray-1000: #3f4248;
  --cpd-color-gray-900: #474a51;
  --cpd-color-gray-800: #6c737e;
  --cpd-color-gray-700: #878f9b;
  --cpd-color-gray-600: #a6adb7;
  --cpd-color-gray-500: #bdc4cc;
  --cpd-color-gray-400: #d7dce3;
  --cpd-color-gray-300: #e1e6ec;
  --cpd-color-gray-200: #f0f2f5;
  --cpd-color-gray-100: #f7f9fa;
  --cpd-color-theme-bg: #ffffff;
}
